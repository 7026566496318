import React from 'react';
import { Row, Col, Alert, Card, Button } from 'react-bootstrap';
import BandCampIFrame from '../../base/iframe';

export default function Home() {
  return (
    <Row>
      <Col xs={12} sm={12} md={6}>
        <BandCampIFrame
          source="https://bandcamp.com/EmbeddedPlayer/album=4089853651/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
          address="https://cristomonte.bandcamp.com/album/fox"
          label="Fox by CristoMonte"
        />
      </Col>
      <Col xs={12} sm={12} md={6}>
        <BandCampIFrame
          source="https://bandcamp.com/EmbeddedPlayer/album=3760808260/size=large/bgcol=ffffff/linkcol=0687f5/tracklist=false/transparent=true/"
          address="https://cristomonte.bandcamp.com/album/tsoar"
          label="Tsoar by CristoMonte"
        />
      </Col>
    </Row>
  );
}
