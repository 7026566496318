import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import PNGImage from '../../assets/img/eye4.2.png';
import PNGImage2 from '../../assets/img/eye3.2.png';
import PNGImage3 from '../../assets/img/eye2.2.png';
import PNGImage4 from '../../assets/img/eye1.1.png';

const EyeBackground = () => {
  const containerRef = useRef(null);

  useEffect(() => {
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xff4366);

    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000,
    );
    camera.position.z = 5;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    containerRef.current.appendChild(renderer.domElement);

    const textureLoader = new THREE.TextureLoader();
    const textures = [
      textureLoader.load(PNGImage),
      textureLoader.load(PNGImage2),
      textureLoader.load(PNGImage3),
      textureLoader.load(PNGImage4),
    ];

    textures.forEach((texture) => {
      texture.colorSpace = THREE.SRGBColorSpace;
    });

    const eyeSprites = [];
    const createSprites = (texture, count = 50, near = false) => {
      const zRange = near ? [1, 3] : [-5, 5];
      for (let i = 0; i < count; i++) {
        const eyeMaterial = new THREE.SpriteMaterial({ map: texture });
        const eyeSprite = new THREE.Sprite(eyeMaterial);
        eyeSprite.position.set(
          (Math.random() - 0.5) * 10,
          (Math.random() - 0.5) * 10,
          Math.random() * (zRange[1] - zRange[0]) + zRange[0],
        );
        scene.add(eyeSprite);
        eyeSprites.push(eyeSprite);
      }
    };

    //Create regular sprites for all textures
    textures.forEach((texture) => createSprites(texture));

    const randomTextureIndex = Math.floor(Math.random() * textures.length);
    const veryCloseSpriteMaterial = new THREE.SpriteMaterial({
      map: textures[randomTextureIndex],
    });

    const veryCloseSprite = new THREE.Sprite(veryCloseSpriteMaterial);
    veryCloseSprite.position.set(0, 0, 4.2);
    scene.add(veryCloseSprite);
    eyeSprites.push(veryCloseSprite);

    const animate = () => {
      requestAnimationFrame(animate);
      eyeSprites.forEach((eyeSprite) => {
        eyeSprite.position.x += Math.random() * 0.02 - 0.01;
        eyeSprite.position.y += Math.random() * 0.02 - 0.01;
        eyeSprite.position.z += Math.random() * 0.02 - 0.01;
      });
      renderer.render(scene, camera);
    };

    animate();

    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      renderer.setSize(width, height);
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      containerRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div className="eye-background" ref={containerRef} />;
};

export default EyeBackground;
