import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Navbar, Nav } from 'react-bootstrap';

function Header() {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      // bg="light"
      // variant="light"
      className="mb-3"
    >
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <h1 className="Optic">SLING YOUR HOOK</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/">
              Home
            </Nav.Link>
            {/* <Nav.Link as={NavLink} to="/mix">
              Mix
            </Nav.Link> */}
            <Nav.Link href="https://cristomonte.bandcamp.com/" target="_blank">
              Bandcamp
            </Nav.Link>
            <Nav.Link as={NavLink} to="/contact">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default React.memo(Header);
