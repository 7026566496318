import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Home from '../views/Home/home.js';
import Mix from '../views/Mix/mix.js';
import Contact from '../views/Contact/contact.js';

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      {/* <Route path="/mix" element={<Mix />} /> */}
      <Route path="/contact" element={<Contact />} />
      <Route
        path="*"
        element={
          <main>
            <h1 className="mt-2 mb-4">Page not found</h1>
            <p>
              Please visit the <Link to="/">home page.</Link>
            </p>
          </main>
        }
      />
    </Routes>
  );
}
