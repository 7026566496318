import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';

export default function Mix() {
  return (
    <Row>
      <Col xs={12} sm={12} md={6}>
        <p>
          For all other enquiries, such as licensing, press, DJ bookings, and
          demos,
          <a href="mailto:cristo.monte@slingyourhook.co.uk">
            {' '}
            please contact us here.
          </a>
        </p>
      </Col>
    </Row>
  );
}
