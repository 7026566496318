import React from 'react';
import { Container } from 'react-bootstrap';
import Routing from './components/routing/routing.js';
import Header from './components/base/header.js';
import EyeBackground from '../src/components/base/eyeBackground';

export default function App() {
  return (
    <>
      <Header />
      <div className="content">
        <Container>
          <EyeBackground />
          <Routing />
        </Container>
      </div>
    </>
  );
}
