const BandCampIFrame = (props) => {
  const iframeStyle = {
    width: '350px',
    height: '470px',
    border: 'none',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
  };

  return (
    <div>
      {/* <iframe
        title="Embedded Content"
        style={iframeStyle}
        src={props.src}
        allowFullScreen
      ></iframe> */}
      <iframe style={iframeStyle} src={props.source} seamless>
        <a href={props.address}>{props.label}</a>
      </iframe>
    </div>
  );
};

export default BandCampIFrame;
